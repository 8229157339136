import { reactive } from 'vue'

export interface IUser {
  username: string,
  password: string
}

// save user to sessionStorage, so the user stays logged in when a refresh is done
// @ts-expect-error non-overlapping cast
const user: IUser = reactive(JSON.parse(sessionStorage.getItem('user') as IUser) || {
  username: '',
  password: ''
});

export function useUser() {
  const login = (v: IUser) => {
    user.username = v.username
    user.password = v.password
    sessionStorage.setItem('user', JSON.stringify(user))
  }

  const logout = () => {
    user.username = ''
    user.password = ''
  }

  return { user, login, logout }
}
